/*****************************
 * Resets and Default Styles
 ****************************/
body{
	font-family: 'Lora', serif;
}

#main-wrapper{
	overflow: hidden; 
}

.img-holder{
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

/*****************************
 * Header Styles
 ****************************/
body.logged-in .header{
	top: 30px;
}
.header{
	/*position: relative;*/ 
	ul, ol{
		margin: 0;
		padding: 0;
		list-style: none; 
	}
	.brand{
		position: absolute;
		left: 0;
		right: 0;
		top: 22px;
		margin: 0 auto;
		max-width: 93px;
	}
}
	.top-nav{
		background-color: #014b96;
		ul{
			padding: 40px 0 34px;
			li{
				margin-left: 20px;
				a{
					color: #e0e0e0;
					font-size: 14px;
					i{
						font-size: 20px;
						margin-right: 8px;
					}
				}
			}
			li:first-child{
				margin-left: 0; 
			}
		} 
	}
	.main-nav{
		background-color: rgba(255, 255, 255, 0.9);  
		ul li{
			a{
				color: #014b96;
				font-size: 18px;	
				display: block;			
			}
		}
	}

/*****************************
 * Block Styles
 ****************************/
.block-326239c4{
	.carousel-indicators li{
		margin: 0 5px;
	}
}
.block-f39346a3{
	padding: 40px 0 25px;
	h1{
		display: none;
	}
	p{
		color: #565656;
		font-size: 18px;
		text-align: center;
	}
}
.item{
	height: 800px;
	.carousel-caption{
		h1{
			font-size: 48px;
			em{
				color: #ffe400;
				font-style: normal;
			}
		}
	}
}

/*****************************
 * Footer Styles
 ****************************/
.footer{
	background-color: #014b96;
	padding: 50px 0 40px;
	p{
		color: #e0e0e0;
		font-size: 14px;
		a{
			color: #e0e0e0;
			text-decoration: underline;
		}
	}
	.sponsor-logos{
		margin-top: 20px;
		text-align: center;
		img{
			margin: 0 30px;
		}
	}
	.social-networks{
		a{
			color: #fff;
			font-size: 20px;
			margin: 0 8px;
		}
	}
}

/*****************************
 * Media Queries Styles
 ****************************/
@media (min-width: 768px){
	.navbar-nav>li{
		margin: 0 0 0 66px;
	}
	.navbar-nav>li:first-child{
		margin-left: 0;
	}
	.navbar-nav>li>a {
	  padding-top: 18px;
	  padding-bottom: 18px;
	}
}

@media (min-width: $screen-lg-min){
	.container{
		width: 1210px;
	}
}